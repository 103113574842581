import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AccessRoute };
