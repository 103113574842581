import { Card } from 'reactstrap';

const Process = (): JSX.Element => (
  <>
    <div>
      <div className="mt-7">
        <h2 className="text-2xl font-bold"> What is the role of accredited investors?</h2>
        <p>
          For companies raising capital, the accredited investor definition largely determines who is in their pool of
          potential investors, and for investors whether they are eligible to invest in many early-stage companies. Many
          of the offering exemptions under the federal securities laws limit participation to accredited investors or
          contain restrictions on participation by non-accredited investors.
        </p>
      </div>
      <div className="mt-7">
        <h2 className="text-2xl font-bold">How can individuals qualify as accredited?</h2>
        <p>
          Individuals (i.e., natural persons) may qualify as accredited investors based on wealth and income thresholds,
          as well as other measures of financial sophistication.
        </p>
        <div className=" tw-grid md:tw-grid-cols-2 gap-4">
          <Card className=" secondary-card secondary-card my-3">
            <h1 className="text-xl font-bold">Financial Criteria</h1>
            <ul className="list-disc m-3">
              <li>Net worth over $1 million, excluding primary residence (individually or with spouse or partner)</li>
              <li>
                Income over $200,000 (individually) or $300,000 (with spouse or partner) in each of the prior two years,
                and reasonably expects the same for the current year
              </li>
            </ul>
          </Card>
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Professional Criteria</h1>
            <ul className="list-disc m-3">
              <li>
                Investment professionals in good standing holding the general securities representative license (Series
                7), the investment adviser representative license (Series 65), or the private securities offerings
                representative license (Series 82)
              </li>
              <li>
                Directors, executive officers, or general partners (GP) of the company selling the securities (or of a
                GP of that company)
              </li>
              <li>Any “family client” of a “family office” that qualifies as an accredited investor</li>
              <li>For investments in a private fund, “knowledgeable employees” of the fund</li>
            </ul>
          </Card>
        </div>
      </div>
      <div className="mt-7">
        <h2 className="text-2xl font-bold">How can entities qualify as accredited?</h2>
        <p>
          Depending upon the structure of the entity or its assets, entities may qualify as an accredited investors.
        </p>
        <div className="tw-grid tw-grid-cols-2 md:grid-cols-2 gap-4 auto-rows-max mt-6">
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Investments</h1>
            <ul className="list-disc m-3">
              <li>Entities owning investments in excess of $5 million</li>
            </ul>
          </Card>
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Assets</h1>
            <ul className="list-disc m-3">
              <li>
                The following entities with assets in excess of $5 million: corporations, partnerships, LLCs, trusts,
                501(c)(3) organizations, employee benefit plans, “family office” and any “family client” of that office
              </li>
            </ul>
          </Card>
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Owners as Accredited</h1>
            <ul className="list-disc m-3">
              <li>Entities where all equity owners are accredited, investors</li>
            </ul>
          </Card>
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Investment Advisers</h1>
            <ul className="list-disc m-3">
              <li>
                Investment advisers (SEC- or state-registered or exempt reporting advisers) and SEC-registered
                broker-dealers
              </li>
            </ul>
          </Card>
          <Card className="secondary-card my-3">
            <h1 className="text-xl font-bold">Financial Entities</h1>
            <ul className="list-disc m-3">
              <li>
                A bank, savings and loan association, insurance company, registered investment company, business
                development company, or small business investment company or rural business investment company
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  </>
);

export default Process;
