import React, { Suspense, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//import Scss
import './assets/scss/themes.scss';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import './tailwind.css';
import Routes from './Routes';
//imoprt Route
import { ToastContainer } from 'react-toastify';
import './i18n';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const { i18n } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const language = query.get('lang');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    } else {
      // If no language query parameter, set default to English
      i18n.changeLanguage('en');

      // Create a new URL with the existing query parameters
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('lang', 'en');

      // Update the URL without removing existing query parameters
      history.replace(newUrl.pathname + newUrl.search);
    }
  }, [language, i18n]);

  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          theme="light"
          icon={true}
        />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
