import { Redirect } from 'react-router-dom';

// import IVMFundingSignup from 'pages/IVMFunding/FundingSignup';
import AccreditPage from 'pages/IVMFunding/accredit';
import { ValidatePage } from 'pages/Validate';

const publicRoutes = [
  // Authentication Page
  // { path: '/individual-enroll', component: IVMFunding },
  // { path: '/organization-enroll', component: OrganizationFunding },
  { path: '/validate', component: ValidatePage },
  // { path: '/privacy', component: PrivacyPolicy },
  // { path: '/terms', component: TermsOfService },
  // { path: '/data-deletion', component: DataDeletion },
  {
    path: '/',
    component: () => (
      <Redirect
        to="/validate"
        // component: () => <Redirect to="/welcome-dashboard"
      />
    ),
  },
  {
    path: '/individual-enroll-signup',
    component: () => (
      <Redirect
        to="/individual-enroll"
        // component: () => <Redirect to="/welcome-dashboard"
      />
    ),
  },
  { path: '/certify', component: AccreditPage },
];

export { publicRoutes };
