import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import ReactCodeInput from 'react-code-input';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export const ValidatePage = () => {
  const [isPhonePinCodeValid] = useState(true);
  const [smsCode, setSmsCode] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isValidCode, setIsValidCode] = useState(null) as any;

  const emailParam = query.get('email') || '';
  const email = decodeURIComponent(emailParam.trim().replace(/ /g, '+'));
  const headers = {
    'Content-Type': 'application/json',
  };
  const validateCodes = async () => {
    if (!smsCode) {
      setError(t('validatePage.emailTextRequired'));
      return;
    }
    setIsSubmitted(true);
    const validateUrl = 'http://nnn.cloud/.netlify/functions/get-validate-code';
    const statusUpdateUrl = 'http://nnn.cloud/.netlify/functions/update-person-status';
    axios
      .post(validateUrl, { email, smsCode }, { headers })
      .then((response: any) => {
        console.log(response);
        setIsSubmitted(false);
        const isValidated = response.otpSmsValid;
        setIsValidCode(isValidated);
        if (response.hsObjectId && isValidated) {
          axios
            .post(statusUpdateUrl, { hsContactId: response.hsObjectId, personStatus: 'Pending Vogon Id' }, { headers })
            .then((response: any) => {
              console.log(response);
            });
        }
      })
      .catch(error => {
        setIsSubmitted(false);
        console.error('Error:', error);
      });

    // // Continue with validation or submission logic
    // const data: any = await validateEmailCode(email, emailCode, smsCode);
    // console.log(data);
  };

  const resendOTP = () => {
    const resendUrl = 'http://validate.vogon.cloud/.netlify/functions/resend-code';

    axios
      .patch(resendUrl, { email })
      .then((response: any) => {
        console.log(response);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div id="validate-page" className="tw-bg-black tw-h-full">
      <Container className="py-lg-0 tw-text-center tw-m-auto">
        {!email && (
          <div className="flex tw-items-center tw-justify-center" style={{ height: '50vh' }}>
            <h2 className="tw-text-3xl text-white tw-text-center tw-py-8 ">{t('validatePage.linkInvalid')}</h2>
          </div>
        )}
        {email && (
          <>
            <div className="tw-mt-2">
              <img
                src="images/vogon_validate_top.jpg"
                alt="NNN Validate"
                className=" tw-m-auto tw-lg:w-1/2  tw-w-full"
              />
            </div>

            <div>
              <Card className="bg-dark-2 tw-m-auto tw-lg:w-1/2  tw-w-full">
                {!isValidCode && (
                  <CardHeader className="bg-dark-2 flex tw-justify-center item-center tw-items-center tw-border-none text-white tw-text-lg">
                    {t('validatePage.enterCodes')}
                  </CardHeader>
                )}
                <CardBody className="text-white tw-p-10 tw-text-center">
                  {!isValidCode && (
                    <Row>
                      <Col md={12}>
                        <label className="tw-block">{t('validatePage.smsCode')}*</label>
                        <ReactCodeInput
                          name="phone"
                          type="text"
                          fields={6}
                          inputMode="verbatim"
                          isValid={isPhonePinCodeValid}
                          value={smsCode}
                          onChange={setSmsCode}
                          inputStyle={{
                            margin: '2px',
                            MozAppearance: 'textfield',
                            width: '40px',
                            padding: '10px',
                            borderRadius: '3px',
                            fontSize: '20px',
                            height: '36px',
                            textAlign: 'center',
                            color: 'black',
                            border: '1px solid black',
                          }}
                        />
                        <div className="tw-mt-1 tw-text-xsm">
                          {t('validatePage.noTextCode')}{' '}
                          <a href="#" onClick={resendOTP}>
                            {t('validatePage.resend')}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {isValidCode && (
                    <div className="tw-py-16">
                      <h2 className="text-2xl text-primary">{t('validatePage.validationSuccess')}</h2>
                      <p className="text-lg">{parse(t('validatePage.validationSuccessInfo'))}</p>
                    </div>
                  )}

                  {error && <p className="error-message text-primary">{error}</p>}
                  {isValidCode !== null && (
                    <div className="error-message text-primary text-center mt-4 text-lg">
                      {isValidCode ? '' : t('validatePage.invalidCode')}
                    </div>
                  )}
                </CardBody>
                {!isValidCode && (
                  <>
                    {/* <div className="text-white tw-text-center">{t('validatePage.validationRequiresBothCodes')}</div> */}
                    <button
                      id="subscribe-form-submit"
                      name="subscribe-form-submit"
                      className={`btn px-4 shadow-none tw-bg-white tw-text-black tw-uppercase tw-w-full tw-text-xl ${
                        isSubmitted ? 'disabled' : ''
                      }`}
                      onClick={validateCodes}
                    >
                      {isSubmitted ? t('validatePage.validating') : t('validatePage.validate')}
                    </button>
                  </>
                )}
              </Card>
            </div>
            <div>
              <img
                src="images/vogon_validate_bottom.jpg"
                alt="NNN Validate"
                className=" tw-m-auto tw-lg:w-1/2  tw-w-full md:w-1/2 lg:w-1/2"
              />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
