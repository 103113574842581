import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { publicRoutes } from './allRoutes';
import { AccessRoute } from './AuthProtected';

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map(r => r.path);

  const location = useLocation();

  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
            ))}
          </Switch>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
