import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationRu from './locales/ru.json';
import translationDe from './locales/de.json';
import translationZh from './locales/zh.json';
import translationHi from './locales/hi.json';
import translationEs from './locales/es.json';

import translationAR from './locales/ar.json';
import translationENG from './locales/en.json';
import translationFR from './locales/fr.json';

// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  es: {
    translation: translationEs,
  },
  ru: {
    translation: translationRu,
  },
  de: {
    translation: translationDe,
  },
  zh: {
    translation: translationZh,
  },
  hi: {
    translation: translationHi,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'en');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
