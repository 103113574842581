import { useState } from 'react';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import Process from './process';
import { Card, Row } from 'reactstrap';
import '../ivm-style.scss';

const AccreditPage = ({ onAccredit }): JSX.Element => {
  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disabled] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [userAccreditationStatus] = useState<Record<string, any>>({
    date: new Date().toISOString(),
  });
  const [, setAccreditedData] = useState<Record<string, any>>({});
  const submitAccreditationStatusState = { error: '' };

  const nonAccredited = 'non_accredited';
  const onChange = (e: CheckboxChangeParams): void => {
    if (e.checked) {
      if (e.value === nonAccredited) {
        setSelected([e.value]);
      } else {
        setSelected([...selected.filter(value => value !== nonAccredited), e.value]);
      }
    } else {
      setSelected(selected.filter(value => value !== e.value));
    }
  };

  const onSubmit = (data: Record<string, any>): void => {
    if (!selected.length) {
      setShowError(true);
      return;
    }
    onAccredit(selected);

    const isAccredited = !selected.includes(nonAccredited);
    setAccreditedData({
      isAccredited,
      selected,
      ...data,
    });
    setShowConfirmation(true);
  };

  const onAccept = async (): Promise<void> => {};

  const Header = (): JSX.Element => (
    <div>
      <div className="flex tw tw-justify-center items-center mb-3">
        <h1 className="text-4xl text-heading">Welcome</h1>
      </div>

      <h1 className="text-4xl text-center font-bold">Our self-certification process</h1>
      <p className="text-center">On this page participants will determine their accreditation status.</p>
    </div>
  );

  return (
    <>
      <Dialog header="Error" visible={showError} onHide={() => setShowError(false)}>
        {submitAccreditationStatusState.error ? (
          <p>{`${submitAccreditationStatusState.error}`}</p>
        ) : (
          <p>You must select at least one accreditation status for The Applicant</p>
        )}
      </Dialog>
      <ConfirmDialog
        header="Confirm"
        message="You will not be able to change accreditation status yourself after submitting"
        visible={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        accept={onAccept}
      />

      <Row>
        <Header />
        {disabled && (
          <div className="my-12 text-4xl">
            <i className="pi pi-check text-4xl mr-3 text-bold text-primary" />
            You have already certified. Below is the certification information and signature for reference.
          </div>
        )}
        <Process />
        <div className="lg:w-2/3 m-auto mt-12">
          <div className="flex tw-justify-center items-center mb-3">
            <h1 className="text-4xl text-heading">Investor</h1>
          </div>
          <h1 className="text-4xl text-center font-bold">ACCREDITED INVESTOR SELF-CERTIFICATION</h1>
          <p className="text-justify">
            The Applicant hereby certifies to CrowdPoint Technologies Inc their accreditation status as defined in Rule
            501 of Regulation D promulgated under the Securities Act of 1933, because (check appropriate statement(s)):
          </p>
        </div>
        <Card className="secondary-card my-3">
          <div className="flex flex-row gap-4 items-center">
            <Checkbox
              inputId="accredited_net_worth"
              name="statement"
              value="accredited_net_worth"
              onChange={onChange}
              checked={selected.includes('accredited_net_worth')}
              disabled={disabled}
            />
            <label htmlFor="accredited_net_worth">
              The Applicant is a natural person whose individual net worth (or joint net worth with such person's
              spouse) exceeds $1,000,000 (excluding in such calculation the value of such person's primary residence and
              the related amount of indebtedness secured by the primary residence up to its fair market value and
              including in such calculation, if applicable, the related amount of indebtedness secured by the primary
              residence that exceeds its fair market value); and/or
            </label>
          </div>
        </Card>
        <Card className="secondary-card my-3">
          <div className="flex flex-row gap-4 items-center">
            <Checkbox
              inputId="accredited_income"
              name="statement"
              value="accredited_income"
              onChange={onChange}
              checked={selected.includes('accredited_income')}
              disabled={disabled}
            />
            <label htmlFor="accredited_income">
              The Applicant is a natural person who had an individual income1 in excess of $200,000 in each of the two
              most recent years and who reasonably expects to have an individual income in excess of $200,000 in the
              current year or who had joint income2 in excess of $300,000 in each of the two most recent years and who
              reasonably expects to have joint income in excess of $300,000 in the current year.
            </label>
          </div>
        </Card>
        <Card className="secondary-card my-3">
          <div className="flex flex-row gap-4 items-center">
            <Checkbox
              inputId="non_accredited"
              name="statement"
              value="non_accredited"
              onChange={onChange}
              checked={selected.includes('non_accredited')}
              disabled={disabled}
            />
            <label htmlFor="non_accredited">
              The Applicant does not meet either one of the previous selections and is certifying themselves as
              unaccredited
            </label>
          </div>
        </Card>

        <div className="mb-40">
          {/* {loading && <ProgressSpinner />} */}
          {disabled && <p className="text-2xl">Self-certification complete. Thank you.</p>}
          {userAccreditationStatus.isAccredited && (
            <p className="text-2xl mt-3">
              You may now make your{' '}
              <Link to="/crwdcapital/payment" style={{ color: 'var(--primary-color)' }}>
                payment.
              </Link>
            </p>
          )}
        </div>

        <div className="mb-6">
          <div className="mb-6 flex tw-justify-center">
            <h1 className="text-4xl text-heading">Definitions</h1>
          </div>
          <div className="mb-6">
            <p className="mt-2">
              <span className="font-bold text-xl" style={{ color: 'var(--primary-color)' }}>
                “Individual income”
              </span>{' '}
              means adjusted gross income as reported for federal income tax purposes, less any income attributable to a
              spouse or to property owned by a spouse, increased by the following amounts (but not including any amounts
              attributable to a spouse or to property owned by a spouse): (i) the amount of any interest income received
              which is tax-exempt under § 103 of the Internal Revenue Code; (ii) the amount of losses claimed as a
              limited partner in a limited partnership (as reported on Schedule E of Form 1040); (iii) any deduction
              claimed for depletion under § 611 et seq. of the Internal Revenue Code; and (iv) any amount by which
              income from long-term capital gains has been reduced in arriving at adjusted gross income pursuant to the
              provisions of § 1202 of the Internal Revenue Code prior to its repeal by the Tax Reform Act of 1986.
            </p>
          </div>
          <div className="mb-6">
            <p className="mt-2">
              <span className="font-bold text-xl" style={{ color: 'var(--primary-color)' }}>
                “Joint income”
              </span>{' '}
              means adjusted gross income as reported for federal income tax purposes, including any income attributable
              to a spouse or to property owned by a spouse, increased by the following amounts (including any amounts
              attributable to a spouse or to property owned by a spouse): (i) the amount of any interest income received
              which is tax-exempt under § 103 of the Internal Revenue Code; (ii) the amount of losses claimed as a
              limited partner in a limited partnership (as reported on Schedule E of Form 1040); (iii) any deduction
              claimed for depletion under § 611 et seq. of the Internal Revenue Code; and (iv) any amount by which
              income from long-term capital gains has been reduced in arriving at adjusted gross income pursuant to the
              provisions of § 1202 of the Internal Revenue Code prior to its repeal by the Tax Reform Act of 1986.
            </p>
          </div>
        </div>
      </Row>
      <Row>
        <button
          type="button"
          className="tw-w-auto btn btn-success btn-label right ms-auto nexttab nexttab mt-3"
          onClick={onSubmit}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Submit
        </button>
      </Row>
    </>
  );
};

export default AccreditPage;
